"use client";
import "aos/dist/aos.css";

import NavHeader from "~/app/_components/landing/nav/nav-header";

import Footer from "~/app/_components/landing/footer";

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  // useEffect(() => {
  //   AOS.init({
  //     once: true,
  //     disable: 'phone',
  //     duration: 700,
  //     easing: 'ease-out-cubic',
  //   });
  // });
  return (
    <>
      {/* This div makes sure the footer is always at the bottom of the screen regardless of how short the page is */}
      <div className="lines flex min-h-screen flex-col">
        <NavHeader border={false} />
        <main className="flex-1">{children}</main>
        <Footer />
      </div>
    </>
  );
}
